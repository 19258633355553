import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { OutboundLink } from 'gatsby-plugin-google-analytics';
import ContentLayout from '../../../components/ContentLayout';
import CommunityNavigation from '../../../components/community/CommunityNavigation';
import SponsorshipNavigation from '../../../components/community/SponsorshipNavigation';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children
}) => <ContentLayout title="Sponsorship" nav={<CommunityNavigation />} subNav={<SponsorshipNavigation />}>
    {children}
    <hr />
    <p className="has-text-centered">
      <OutboundLink className="button is-primary" href="http://bit.ly/SDG-supporter-form">
        Support SDG
      </OutboundLink>
    </p>
  </ContentLayout>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3>{`Who we are`}</h3>
    <p>{`The `}<strong parentName="p">{`Academy at Suncoast Developers Guild`}</strong>{` is a code school that serves `}<em parentName="p">{`people`}</em>{`, not `}<em parentName="p">{`profit`}</em>{`. We are changing lives and teaching people to be the best software developers they can be.`}</p>
    <p>{`Not only does our coding program teach our students what they need to be successful coders, but we also strive to help them to fulfill their career goals by teaching them the soft skills they need in order for them to be proactive be confident while engaging with possible employers.`}</p>
    <h3>{`If you choose to give`}</h3>
    <h4>{`Your Donation is Tax Deductible.`}</h4>
    <p>{`We are a 501(c)(3) organization that supports the Tampa Bay tech community through our educational program and community events such as free crash courses, meetups, and our SDG Jr program.`}</p>
    <h4>{`Your Donation Will Change Someone's Life.`}</h4>
    <p>{`Whether you donate equipment or money your donation will be put towards the betterment of a student or the Tampa Bay community.`}</p>
    <h4>{`Your Donation is Secure`}</h4>
    <p>{`All donations will go directly towards running the Suncoast Developers Guild’s programs and its students.`}</p>
    <h3>{`How do you benefit?`}</h3>
    {
      /* prettier-ignore */
    }
    <p>{`According to Monster, the average in-house recruitment cost of filling a vacancy, using internal or external recruitment methods is reported to be around $9,000.`}</p>
    <p>{`65% of recruiters claim talent shortage is the biggest challenge in hiring.`}</p>
    <p>{`In 2016, 58% of small businesses reported "hiring or trying to hire" and 52% reported scarcity or lack of qualified applicants for their open positions.`}</p>
    <p>{`SDG not only teaches our students how to code, we teach them how to think like developers. Our students are able to enter the workforce immediately. By teaming up with our program you can help bring new developers that are eager to work hard and continue to learn into the workforce.`}</p>
    <p>{`We are cutting down on lack of qualified applicants and the time it can take to find them.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      